<template>
	<div>
		<h3 style="text-align: center" v-if="isStart">{{ recorderTime }}</h3>
		<el-button
			type="primary"
			@click="startRecordAudio"
			round
			size="small"
			:style="`${recorder.duration ? 'width: 140px;text-align:center;padding: 0; border: none' : 'padding: 0;width: 140px;text-align:center'}`"
		>
			<el-button
				type="warning"
				:icon="recorder.ispause ? 'el-icon-video-play' : 'el-icon-video-pause'"
				circle
				v-if="isStart"
				@click.stop="pauseOrResume"
				style="float: left"
				size="small"
			></el-button>
			<div type="text" size="small" style="color: #fff; padding: 0; display: inline-flex; height: 32px; line-height: 32px">
				{{ recorder.ispause ? '暂停' : isStart ? '录音中' : '开始录音' }}
			</div>
			<el-button
				type="danger"
				icon="el-icon-switch-button"
				circle
				v-if="isStart"
				@click.stop="stopRecordAudio"
				style="float: right"
				size="small"
			>
			</el-button
		></el-button>
	</div>
</template>

<script>
import Recorder from '../../../../public/static/js/recorder';
import moment from 'moment';
import { Loading } from 'element-ui';
// import lamejs from 'lamejs';
// import { Work } from '@/utils/util';
const uuid = require('uuid');

export default {
	name: 'audioRecorder',
	props: { id: {}, userInfo: {} },
	data() {
		return {
			recorder: new Recorder({
				sampleBits: 8, // 采样位数  8 或 16，默认是16
				sampleRate: 8000, // 支持 11025、16000、22050、24000、44100、48000
				numChannels: 1 // 声道，支持 1 2， 默认是1
			}),
			// sampleRate: 16000,
			recordingId: '',
			chunkTime: 10000, // 分片时间
			seqNo: 0,
			chunks: [],
			loading: {},
			isDownload: false,
			refetchTime: 5000, // 失败 重复请求间隔
			chunkRecorderTime: 0
		};
	},
	computed: {
		recorderTime() {
			const s = this.recorder.duration + this.chunkRecorderTime;

			return moment.utc(Math.floor(s * 1000)).format('HH:mm:ss');
		},
		isStart() {
			const d = this.recorder.duration + this.chunkRecorderTime;

			return d > 0;
		}
	},
	mounted() {
		// const script = document.createElement('script');
		// script.type = 'text/javascript';
		// script.src = '/static/js/lame.min.js';
		// document.body.appendChild(script);
	},
	methods: {
		// 开始录音
		startRecordAudio() {
			if (this.isStart) {
				return;
			}
			Recorder.getPermission().then(
				() => {
					console.log('开始录音');
					this.recorder.start(); // 开始录音
					this.recordingId = uuid.v4();
					this.chunkRecorderTime = 0;
					// 上传录音文件
					this.uploadWAVData();
				},
				() => {
					this.$message({
						message: '请允许使用麦克风',
						type: 'info'
					});
				}
			);
		},
		// 上传完成 录音组件重置
		resetRecorder() {
			this.chunks = [];
			this.seqNo = 0;
			// this.recorder.stream.getAudioTracks().forEach((t) => t.stop());
			this.chunkRecorderTime = 0;
			this.recorder.destroy();
			this.isDownload = false;
		},
		// 停止录音 合并录音
		async stopRecordAudio() {
			this.loading = Loading.service({ target: '.recorderWrap' });

			clearInterval(this.timer);
			this.recorder.stop();
			if (this.isDownload) {
				this.loading.close();
				this.recorder.downloadWAV(`录音文件-${this.userInfo.userName}`);
				// 重置
				this.resetRecorder();
				return;
			}
			this.uploadEnd()
				.then(() => {
					this.loading.close();
					// 重置
					this.resetRecorder();
					this.$message.success('录音文件上传成功');
					// 成功了 则合并
					this.$axios.post('/v1/internal/talent/mergeChunks', { request: { groupId: this.id, recordingId: this.recordingId } });
					// .then((r) => {
					// 	if (r.code) {
					// 		return;
					// 	}
					// });
				})
				.catch(() => {
					// 上传失败 下载文件
					this.loading.close();
					this.recorder.downloadWAV(`录音文件-${this.userInfo.userName}`);
					this.resetRecorder();
				});
		},
		// 播放录音
		playRecordAudio() {
			console.log('播放录音');
			this.recorder.play();
		},
		fetchErrorDo() {
			this.$message({ type: 'warning', message: '服务器出现问题，将会在录音结束后自动下载录音文件到本地！', showClose: true, duration: 6000 });
			this.isDownload = true;
			// 超过规定请求次数 改用本地下载
			if (this.timer) {
				clearInterval(this.timer);
			}
		},
		// 上传失败处理
		uploadError(seqNo) {
			if (this.chunks?.length === 0) {
				return;
			}
			const errorSeq = seqNo;
			const maxErrorNum = this.chunks.find(({ errorNum }) => errorNum > 4)?.errorNum;

			if (maxErrorNum > 4) {
				// 5次失败则不进行请求
				this.fetchErrorDo();
				return;
			}

			this.chunks.forEach((val) => {
				if (val.seqNo === errorSeq) {
					if (val.errorNum) {
						val.errorNum++;
					} else {
						val.errorNum = 2; // 从第二次开始记录
					}
				}
			});
			// 失败次数未超过 将重新请求
			this.uploadServer(errorSeq.toString());
		},
		// 上传wav录音数据
		uploadWAVData() {
			if (this.timer) {
				clearInterval(this.timer);
			}
			this.timer = setInterval(() => {
				if (this.recorder.ispause) {
					return;
				}
				// 上传服务器
				this.uploadServer();
			}, this.chunkTime);
		},
		// 录音结束上传
		uploadEnd() {
			return new Promise((resolve, reject) => {
				let num = 1;
				const fn = () => {
					this.uploadServer(null, true).then((res) => {
						if (res) {
							if (this.isDownload) {
								return reject(null);
							}
							resolve(res);
						} else {
							num++;
							if (num > 5) {
								this.fetchErrorDo();
								reject(null);
								return;
							}
							setTimeout(() => {
								fn();
							}, this.refetchTime);
						}
					});
				};

				fn();
			});
		},
		audioBufferToWav(abuffer, len) {
			const numOfChan = abuffer.numberOfChannels;
			const length = len * numOfChan * 2 + 44;
			const buffer = new ArrayBuffer(length);
			const view = new DataView(buffer);
			const channels = [];

			// eslint-disable-next-line init-declarations
			let i;

			// eslint-disable-next-line init-declarations
			let sample;

			let offset = 0;

			let pos = 0;

			setUint32(0x46464952);
			setUint32(length - 8);
			setUint32(0x45564157);
			setUint32(0x20746d66);
			setUint32(16);
			setUint16(1);
			setUint16(numOfChan);
			setUint32(abuffer.sampleRate);
			setUint32(abuffer.sampleRate * 2 * numOfChan);
			setUint16(numOfChan * 2);
			setUint16(16);
			setUint32(0x61746164);
			setUint32(length - pos - 4);
			for (i = 0; i < abuffer.numberOfChannels; i++) {
				channels.push(abuffer.getChannelData(i));
			}
			while (pos < length) {
				for (i = 0; i < numOfChan; i++) {
					sample = Math.max(-1, Math.min(1, channels[i][offset]));
					// eslint-disable-next-line no-bitwise
					sample = (0.5 + sample < 0 ? sample * 32768 : sample * 32767) | 0;
					view.setInt16(pos, sample, true);
					pos += 2;
				}
				offset++;
			}
			return new Blob([buffer], { type: 'audio/wav' });

			function setUint16(data) {
				view.setUint16(pos, data, true);
				pos += 2;
			}
			function setUint32(data) {
				view.setUint32(pos, data, true);
				pos += 4;
			}
		},
		getWAVBlob() {
			return new Promise((resolve) => {
				const blob = this.recorder.getWAVBlob();

				this.chunkRecorderTime += this.recorder.duration;
				this.recorder.startRecord().then(() => {
					resolve(blob);
				});
			});
		},
		// 创建新audioBuffer
		createNewAudioBuff(fileOfBlob) {
			return new Promise((resolve) => {
				const ctx = new AudioContext();
				const reader = new FileReader();

				reader.onload = (e) => {
					const arrBuff = e.target.result;

					ctx.decodeAudioData(arrBuff, (audioBuff) => {
						const { numberOfChannels, sampleRate, length } = audioBuff;

						const newAudioBuffer = new AudioContext().createBuffer(numberOfChannels, length, sampleRate);
						const anotherArray = new Float32Array(length);
						const offset = 0;

						for (let channel = 0; channel < numberOfChannels; channel++) {
							audioBuff.copyFromChannel(anotherArray, channel, 0);
							newAudioBuffer.copyToChannel(anotherArray, channel, offset);
						}
						resolve({ buffer: newAudioBuffer, length });
					});
				};
				reader.readAsArrayBuffer(fileOfBlob);
			});
		},
		// 服务上传
		async uploadServer(seqStr, isEnd) {
			const uploadErrorFn = (num) => {
				if (!isEnd) {
					setTimeout(() => {
						// 上传出错
						this.uploadError(num);
					}, this.refetchTime);
				}
			};
			// 校验
			const checkRes = await this.$axios
				.post('/v1/internal/talent/checkChunkExist', {
					request: { groupId: this.id, recordingId: this.recordingId }
				})
				.catch(() => {
					uploadErrorFn(seqStr ? Number(seqStr) : this.seqNo);
				});

			if (checkRes?.uploadStatus === false) {
				// 上传
				const wavBlob = await this.getWAVBlob();

				// 重置一下
				// this.recorder.startRecord();

				let { seqNo } = this;
				const fileName = `${new Date().getTime()}.wav`;

				// const fileOfBlob = new File([newBlob], fileName);
				// 转换成audioBuffer
				// const { buffer, length } = await this.createNewAudioBuff(new File([wavBlob], fileName));
				// // audioBuffer转 wav blob
				// const wabBlob = this.audioBufferToWav(buffer, length);

				let sendFile = new File([wavBlob], fileName);

				if (seqStr) {
					// 上传失败的chunk
					seqNo = Number(seqStr);
					sendFile = this.chunks[seqNo]?.blob;
				}
				// console.log(
				// 	this.downLoadBlob(
				// 		new File(
				// 			this.chunks.map((v) => v.blob),
				// 			fileName
				// 		)
				// 	),
				// 	wavBlob,
				// 	this.recorder,
				// 	'sendFile'
				// );
				// setTimeout(() => {
				// const reader = new FileReader();

				// reader.onload = (e) => {
				// 	const arrBuff = e.target.result;
				// 	const samples = new Int16Array(arrBuff);

				// 	const mp3Buff = this.encodeToMp3(1, 48000, samples);

				// 	this.downLoadBlob(new File([mp3Buff], fileName));
				// 	console.log('mp3Buffmp3Buffmp3Buff');
				// };
				// reader.readAsArrayBuffer(sendFile);
				// }, 0);
				const formData = new FormData();

				formData.append('file', sendFile);
				formData.append(
					'params',
					JSON.stringify({
						groupId: this.id,
						fileName,
						seqNo,
						recordingId: this.recordingId
					})
				);
				if (!seqStr) {
					this.chunks.push({ seqNo, blob: sendFile }); // 分片保存
					// 结束录音
					if (!isEnd) {
						this.seqNo++; // 排序递增
					}
				}
				// 上传
				const uploadRes = await this.$axios.post('/v1/internal/talent/uploadChunk', formData).catch(() => {
					uploadErrorFn(seqNo);
				});

				if (uploadRes.code) {
					uploadErrorFn(seqNo);
					return null;
				}
				return uploadRes;
			}
		},
		// 转换mp3
		// encodeToMp3(channelNum, sampleRate, samples) {
		// 	const buffer = [];
		// 	// 128是指输出的MP3音频的比特率bitrate，单位是kbps

		// 	const mp3enc = new lamejs.Mp3Encoder(channelNum, sampleRate, 128);

		// 	let remaining = samples.length;

		// 	const maxSamples = 1152;

		// 	for (let i = 0; remaining >= maxSamples; i += maxSamples) {
		// 		const mono = samples.subarray(i, i + maxSamples);

		// 		const mp3buf = mp3enc.encodeBuffer(mono);

		// 		if (mp3buf.length > 0) {
		// 			buffer.push(new Int8Array(mp3buf));
		// 		}
		// 		remaining -= maxSamples;
		// 	}
		// 	const flushData = mp3enc.flush();

		// 	if (flushData.length > 0) {
		// 		buffer.push(new Int8Array(flushData));
		// 	}

		// 	return new Blob(buffer, { type: 'audio/mp3' });
		// },
		downLoadBlob(blob) {
			const url = URL.createObjectURL(blob);
			const link = document.createElement('a');

			link.href = url;
			link.style.display = 'none';
			link.download = `${new Date().getTime()}.wav`;
			document.body.appendChild(link);
			link.click();
		},
		// 暂停继续
		pauseOrResume() {
			if (!this.recorder.ispause) {
				this.recorder.pause();
			} else {
				this.recorder.resume();
			}
		}
	},
	watch: {},
	beforeDestroy() {
		clearInterval(this.timer);
		// this.recorder.stream?.getAudioTracks().forEach((t) => t.stop());
		this.recorder.destroy();
	}
};
</script>
<style scoped lang="scss"></style>

<template>
	<div>
		<baseTable @request="getTableData" :columns="columns"> </baseTable>
	</div>
</template>

<script>
import baseTable from '@/views/components/components-base-table/index.vue';
import moment from 'moment';
import { setUrlParams } from '@/utils/util';
/* 录音文件 */
export default {
	name: 'recorderList',
	props: { reportId: {} },
	components: { baseTable },
	computed: {
		getOptsById() {
			return (id) => this.$store.state.app?.dict?.filter((item) => item.groupId === id) || [];
		},
		recorderStatusOpts() {
			return this.getOptsById('recorderStatus');
		}
	},
	data() {
		return {
			columns: [
				{ label: '名称', key: 'fileName' },
				{ label: '创建时间', key: 'createTime', formatter: (val) => moment(val).format('YYYY-MM-DD HH:mm:ss') },
				{ label: '时长', key: 'fileLength' },
				{
					label: '状态',
					key: 'status',
					render: ({ status }) => {
						let color = '#333';

						switch (status) {
							case 3:
								color = '#3c8de6';
								break;
							case 4:
								color = '#66de85';
								break;

							default:
								break;
						}
						const style = { color };

						return <span style={style}>{this.recorderStatusOpts.find(({ dictId }) => dictId === status.toString())?.dictName}</span>;
					}
				},
				// { label: '备注', key: 'remark' },
				{
					label: '操作',
					key: 'cz',
					width: '120px',
					fixed: 'right',
					render: (row) => (
						<div style="display:inline-flex;align-items: center;height: 22px">
							{
								<el-button type="text" onclick={(e) => this.handleResule(row, e)} disabled={row.status !== 4}>
									查看结果
								</el-button>
							}
						</div>
					)
				}
			]
		};
	},

	mounted() {},

	methods: {
		getTableData(params, callback) {
			callback(this.$axios.post('/v1/internal/talent/getRecordingList', { request: { reportId: this.reportId, ...params.request } }));
		},
		handleResule({ id, obsFilePath }) {
			// this.$router.push({ path: '/internal/talent/recording-result', query: { reportId: this.reportId } });
			window.open(`/#/recordDetail?params=${setUrlParams({ obsFilePath, id, reportId: this.reportId })}`);
		}
	}
};
</script>

<style lang="scss" scoped></style>

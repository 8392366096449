<template>
	<div class="evaluationCenterDetail">
		<div class="titlebox">
			<el-button size="medium" type="text" @click="$router.go(-1)">返回</el-button>
			<div class="title">
				{{ title }}
				<el-button size="mini" style="float: right" type="text" icon="el-icon-view" @click="handleView">个人报告预览</el-button>
			</div>
		</div>
		<div class="formContent">
			<el-container>
				<el-main>
					<div class="formTitle" style="margin-bottom: 20px" @click="() => handleTitle('1')" id="oAfiDFoQn_1">基本信息</div>
					<el-collapse-transition>
						<formItem
							v-show="activeNames.includes('1')"
							ref="formItem1"
							:formItems="formItems1"
							:defaultData="defaultData.userInfo"
							:showFooter="false"
							style="margin: 10px 30px 10px 0"
							:type="formType"
						/>
					</el-collapse-transition>
					<div class="formTitle" style="margin-bottom: 20px" @click="() => handleTitle('2')" id="oAfiDFoQn_2">关键评估事实</div>
					<el-collapse-transition>
						<div v-show="activeNames.includes('2')">
							<template v-if="factList?.length > 0">
								<div v-for="(item, inx) in factList" :key="inx" class="formIteWrap" :id="`oAfiDFoQn_2_${inx}`">
									<formItem
										ref="formItemList"
										:formItems="formItems2(inx)"
										:defaultData="item"
										:showFooter="false"
										:type="formType"
									/>
									<div style="text-align: center; cursor: pointer" v-if="!isView">
										<i class="el-icon-delete" style="color: red" @click="factList.splice(inx, 1)"></i>
									</div>
								</div>
							</template>
							<empty v-else-if="isView && factList?.length === 0" />
							<div class="addFunctions" @click="factList.push({})" v-if="!isView && factList.length < 5">
								<i class="el-icon-plus" style="margin-right: 4px"></i>添加
							</div>
						</div>
					</el-collapse-transition>

					<div class="formTitle" style="margin-bottom: 20px" @click="() => handleTitle('3')" id="oAfiDFoQn_3" v-if="isCompetencyAssessment">
						管理站位评估
						<el-button icon="el-icon-view" type="text" style="float: right" @click.stop="() => showExplain(1)">评估标准</el-button>
					</div>
					<el-collapse-transition v-if="isCompetencyAssessment">
						<div class="formIteWrap" v-show="activeNames.includes('3')">
							<formItem
								ref="formItem2"
								:formItems="formItems3"
								:defaultData="defaultData"
								:showFooter="false"
								style="margin-bottom: 10px"
								:type="formType"
							/>
						</div>
					</el-collapse-transition>
					<div
						class="formTitle"
						style="margin-bottom: 20px"
						@click="() => handleTitle('4')"
						id="oAfiDFoQn_4"
						v-if="isCompetencyAssessment && !isNotMange"
					>
						管理能力评估
						<el-button icon="el-icon-view" type="text" style="float: right" @click.stop="() => showExplain(2)">评估标准</el-button>
					</div>
					<el-collapse-transition v-if="isCompetencyAssessment && !isNotMange">
						<div class="formIteWrap" v-show="activeNames.includes('4')">
							<formItem
								ref="formItem3"
								:formItems="formItems4"
								:defaultData="defaultData"
								:showFooter="false"
								style="margin-bottom: 10px"
								:type="formType"
							/>
						</div>
					</el-collapse-transition>
					<div
						class="formTitle"
						style="margin-bottom: 20px"
						@click="() => handleTitle('5')"
						id="oAfiDFoQn_5"
						v-if="isCompetencyAssessment && !isNotMange"
					>
						胜任度评估
						<el-button icon="el-icon-view" type="text" style="float: right" @click.stop="() => showExplain(3)">评估标准</el-button>
					</div>
					<el-collapse-transition v-if="isCompetencyAssessment && !isNotMange">
						<div class="formIteWrap" v-show="activeNames.includes('5')">
							<formItem
								ref="formItem4"
								:formItems="formItems5"
								:defaultData="defaultData"
								:showFooter="false"
								style="margin-bottom: 10px"
								:type="formType"
							/>
						</div>
					</el-collapse-transition>

					<div class="formTitle" style="margin-bottom: 20px" @click="() => handleTitle('6')" id="oAfiDFoQn_6" v-if="isQuality">
						潜力素质评估
						<el-button icon="el-icon-view" type="text" style="float: right" @click.stop="() => showExplain(4)">评估标准</el-button>
					</div>
					<el-collapse-transition v-if="isQuality">
						<div class="formIteWrap" v-show="activeNames.includes('6')">
							<formItem
								ref="formItem5"
								:formItems="formItems6"
								:defaultData="defaultData"
								:showFooter="false"
								style="margin-bottom: 10px"
								:type="formType"
							/>
						</div>
					</el-collapse-transition>

					<div class="formTitle" style="margin-bottom: 20px" @click="() => handleTitle('7')" id="oAfiDFoQn_7" v-if="isQuality">
						人才类型评估
						<el-button icon="el-icon-view" type="text" style="float: right" @click.stop="() => showExplain(5)">评估标准</el-button>
					</div>
					<el-collapse-transition v-if="isQuality">
						<div class="formIteWrap" v-show="activeNames.includes('7')">
							<formItem
								ref="formItem6"
								:formItems="formItems7"
								:defaultData="defaultData"
								:showFooter="false"
								style="margin-bottom: 10px"
								:type="formType"
							/>
						</div>
					</el-collapse-transition>

					<div class="formTitle" style="margin-bottom: 20px" @click="() => handleTitle('8')" id="oAfiDFoQn_8">其他信息补充</div>
					<el-collapse-transition>
						<div class="formIteWrap" v-show="activeNames.includes('8')" style="margin-bottom: 80px">
							<formItem
								ref="formItem7"
								:formItems="formItems8"
								:defaultData="defaultData"
								:showFooter="false"
								style="margin-bottom: 10px"
								:type="formType"
							/>
						</div>
					</el-collapse-transition>
				</el-main>
				<el-aside width="160px">
					<div class="aside_anchor">
						<div class="anchor_line"></div>
						<ul class="anchor_text_box">
							<li v-for="{ label, children, index } in anchorList" :key="label" class="anchor_text">
								<a
									href="javascript:void(0)"
									@click="(e) => jumpById(e, `#oAfiDFoQn_${index + 1}`)"
									:style="`color: ${activeId === `#oAfiDFoQn_${index + 1}` ? '#1677ff' : '#333'} `"
									>{{ label }}</a
								>
								<template v-if="children?.length">
									<ul style="margin-top: 4px">
										<li v-for="(it, _i) in children" :key="it.label" class="anchor_text">
											<a
												href="javascript:void(0)"
												@click="(e) => jumpById(e, `#oAfiDFoQn_${index + 1}_${_i}`)"
												:style="`color: ${activeId === `#oAfiDFoQn_${index + 1}_${_i}` ? '#1677ff' : '#333'} `"
												>{{ it.label }}</a
											>
										</li>
									</ul>
								</template>
							</li>
						</ul>
					</div>
					<div class="recorderWrap">
						<!-- 录音 -->
						<recorder style="" v-if="isAssessment" :id="defaultData.id" :userInfo="defaultData.userInfo" />
						<!-- 录音记录 -->
						<el-button
							style="margin-top: 20px; width: 140px; z-index: 1"
							type="primary"
							size="small"
							round
							@click="recorderVisible = true"
							>查看录音文件</el-button
						>
					</div>
				</el-aside>
			</el-container>
		</div>

		<div class="formFooter" v-if="!isAdminView">
			<!-- <el-button @click="$router.go(-1)">取 消</el-button> -->
			<el-button @click="() => submitForm(4)" v-if="isAssessment">保 存</el-button>
			<el-button type="primary" @click="() => submitForm(5)" v-if="isAssessment">提交复核</el-button>
			<el-button type="danger" @click="() => submitForm(6)" v-if="isReviewedPending || isAdminReviewed || isAdminComplete">驳回修改</el-button>
			<el-button type="primary" @click="() => submitForm(7)" v-if="isReviewedPending || isAdminReviewed">复核通过</el-button>
			<el-button type="primary" @click="() => submitForm(1)" v-if="isAdminComplete || planStatusToChange">确认修改</el-button>
		</div>
		<!-- 标准展示表格 -->
		<el-dialog width="80vw" center :visible.sync="tableVisible" @close="activeTab = '1'">
			<baseTable :dataList="dataList" :columns="tableCols" :showPagination="false" v-if="tableVisible" :span-method="spanMethod">
				<div slot="title">
					<div style="text-align: center; line-height: 40px; font-size: 18px; font-weight: 500">
						{{ tableTitle }}
					</div>
					<el-tabs v-model="activeTab" @tab-click="handleTabs" v-if="[3, 4].includes(tableShowType)">
						<el-tab-pane v-for="item in tabsItem[tableShowType]" :key="item.label" :label="item.label" :name="item.value"></el-tab-pane>
					</el-tabs>
				</div>
			</baseTable>
			<baseTable :dataList="dataList1" :columns="tableCols1" :showPagination="false" v-if="[1].includes(tableShowType)">
				<div slot="title" style="text-align: center; line-height: 40px; font-size: 18px; font-weight: 500">{{ tableTitle1 }}</div>
			</baseTable>
		</el-dialog>
		<!-- 新增批注修改 -->
		<el-dialog width="900px" :title="addTitle" center :visible.sync="addNoteVisible">
			<div class="formTitle" style="margin-bottom: 20px" v-if="showFooter">{{ reTitle }}</div>
			<formItem
				ref="formItem_add"
				:formItems="addFormItems"
				:defaultData="addNoteData"
				style="margin: 10px"
				@cancel="addNoteVisible = false"
				@submit="addNoteSubmit"
				:showFooter="showFooter"
				class="dialogLoading"
				v-if="showFooter"
			>
				<div
					style="color: #409eff; text-align: left; margin: 0 0 10px 10px; cursor: pointer"
					v-if="commentList?.length"
					@click="viewMore = !viewMore"
				>
					{{ viewMore ? '隐藏历史记录' : '查看历史记录' }} <span style="color: red; margin-left: 4px" v-if="showIsNew">(新)</span>
				</div>
			</formItem>
			<div class="dialogLoading" v-if="commentList?.length > 0">
				<div class="formTitle" style="margin-bottom: 20px" v-if="viewMore || !showFooter">历史记录</div>
				<el-timeline v-if="viewMore || !showFooter">
					<el-timeline-item v-for="item in commentList" :key="item.id" :timestamp="item.title" placement="top">
						<div style="text-align: right">
							<el-button type="text" @click="() => handleIsRead(item)" v-if="isEdit && item.status !== 0">{{
								item.isRead === 0 ? '已处理' : '未处理'
							}}</el-button>
						</div>
						<el-card>
							<div style="margin-bottom: 4px; text-align: right">
								<i
									class="el-icon-delete"
									style="color: red; cursor: pointer"
									@click="() => deleteComment(item)"
									v-if="handleData.dialogType === 'note' && isEdit && canDelComments"
								></i>
								<a
									v-else-if="handleData.dialogType === 'change' && isEdit"
									style="color: red"
									href="javascript:void(0)"
									@click="() => comeBackTo(item)"
									>退回此版本</a
								>
							</div>
							<el-input
								type="textarea"
								:value="item.comments"
								autosize
								readonly
								class="noBorder"
								v-if="handleData.dialogType === 'note'"
							></el-input>
							<formItem
								v-else
								ref="formItem_add"
								:formItems="addFormItems.slice(0, -1)"
								:defaultData="item"
								style="margin: 10px 30px 10px 0"
								:showFooter="false"
								type="view"
							/>
						</el-card>
					</el-timeline-item>
				</el-timeline>
			</div>
			<empty v-else-if="!showFooter && commentList?.length === 0" class="dialogLoading" />
		</el-dialog>
		<!-- 录音文件 -->
		<el-dialog width="900px" title="录音文件" center :visible.sync="recorderVisible">
			<recorderList :reportId="defaultData.id" v-if="recorderVisible" />
		</el-dialog>
	</div>
</template>

<script>
import formItem from '@/views/components/components-form-item/index.vue';
import baseTable from '@/views/components/components-base-table/index.vue';
import empty from '@/views/components/components-empty/index.vue';
import { getUrlParams, checkFormList, setUrlParams } from '@/utils/util';
import {
	fourManCols,
	fourData,
	wdCols,
	wdData,
	pjData,
	pjCols,
	// srCols,
	// srData,
	srdCols,
	quaData,
	rcData,
	rcCols,
	qlCols,
	qlData
} from './tableDataSet';
import { Loading } from 'element-ui';
import recorder from './recorder';
import recorderList from './recorderList';
// import { throttle } from 'loadsh';
let timer = null;

export default {
	name: 'evaluationCenterDetail',
	components: { formItem, baseTable, empty, recorder, recorderList },
	computed: {
		commentListView() {
			if (!this.viewMore) {
				return [];
			}
			return this.commentList;
		},
		// 基本信息
		formItems1() {
			return [
				{ label: '姓名', key: 'userName', type: 'input', span: 6, rules: this.rules.input },
				{ label: '所属公司', key: 'company', type: 'input', span: 6, disabled: true },
				{
					label: '所属行业',
					key: 'industryName',
					type: 'input',
					disabled: true,
					span: 6
				},
				{
					label: '所在城市',
					key: 'cityName',
					type: 'input',
					disabled: true,
					// props: {
					// 	emitPath: false
					// },
					// options: window.$cityList,
					span: 6
				},
				{ label: '一级组织', key: 'firstOrg', type: 'input', span: 6, rules: this.rules.input },
				{ label: '二级组织', key: 'secondOrg', type: 'input', span: 6 },
				{ label: '三级组织', key: 'thirdOrg', type: 'input', span: 6 },
				{ label: '四级组织', key: 'fourthOrg', type: 'input', span: 6 },
				{ label: '职位', key: 'officeName', type: 'input', span: 6, rules: this.rules.input },
				// { label: '岗位', key: 'jobName', type: 'input', span: 6 },
				{ label: '汇报对象', key: 'directSupervisor', type: 'input', span: 6 },
				{ label: '直接下属人数', key: 'subordinateNum', type: 'inputNumber', span: 6, precision: 0, min: 0 }
			];
		},
		// 关键事实
		formItems2() {
			return (inx) => {
				const skipId = `oAfiDFoQn_2_${inx}`;

				return [
					{
						label: `事实${inx + 1}`,
						labelPosition: 'left',
						labelStyle: 'font-size: 16px;width: 80px',
						dialogTypeId: '10',
						skipId,
						render: this.noteAndEidt.bind(this, `oAfiDFoQn_2_${inx}`)
					},
					{ label: 'S', key: 'situation', labelWidth: '80px', type: 'textarea', rules: this.rules.input, skipId },
					{ label: 'T', key: 'task', labelWidth: '80px', type: 'textarea', rules: this.rules.input, skipId },
					{ label: 'A', key: 'action', labelWidth: '80px', type: 'textarea', rules: this.rules.input, skipId },
					{ label: 'R', key: 'result', labelWidth: '80px', type: 'textarea', rules: this.rules.input, skipId }
				];
			};
		},
		// 管理能力评估
		formItems4() {
			const items = [...this.abilityItemList];

			const arr = [];

			items.forEach((it, _index) => {
				const skipId = `oAfiDFoQn_4_${_index}`;

				arr.push({
					...this.abilityItems.title,
					label: it.title,
					dialogTypeId: (_index + 1).toString(),
					render: this.abilityItems.title.render.bind(this, skipId)
				});
				let levelKey = '';

				it.children.forEach(({ key, prop, templateType }) => {
					const obj = { ...this.abilityItems[key], templateType, _index, key: prop, levelKey, skipId };

					if (key === 'level') {
						levelKey = prop;
						this.$delete(obj, 'rules');
					}
					// 部门运营层 逻辑
					if (
						['strategyMatch', 'strategyKeyDes', 'changeMatch', 'changeKeyDes'].includes(prop) &&
						this.defaultData.initialPosition === '2'
					) {
						this.$delete(obj, 'rules');
					}
					// 业务执行层 逻辑
					if (
						[
							'strategyMatch',
							'strategyKeyDes',
							'changeMatch',
							'changeKeyDes',
							'flowMatch',
							'flowKeyDes',
							'advocateMatch',
							'advocateKeyDes'
						].includes(prop) &&
						this.defaultData.initialPosition === '3'
					) {
						this.$delete(obj, 'rules');
					}
					arr.push(obj);
				});
			});
			return arr;
		},
		// 潜力素质评估
		formItems6() {
			const items = [...this.qualityItemList];

			const arr = [];

			items.forEach((it, _index) => {
				const skipId = `oAfiDFoQn_6_${_index}`;

				arr.push({
					...this.abilityItems.title,
					label: it.title,
					dialogTypeId: (5 + _index).toString(),
					render: this.abilityItems.title.render.bind(this, skipId),
					skipId
				});
				let levelKey = '';

				it.children.forEach(({ key, prop, templateType }) => {
					arr.push({ ...this.abilityItems[key], templateType, _index, levelKey, key: prop, skipId });
					if (key === 'level') {
						levelKey = prop;
					}
				});
			});

			return arr;
		},
		// 锚点跳转
		anchorList() {
			return [
				{ label: '基本信息', index: 0 },
				{ label: '关键评估事实', index: 1, children: this.factList.map((v, _inx) => ({ label: `事实${_inx + 1}` })) },
				this.isCompetencyAssessment && { label: '管理站位评估', index: 2 },
				this.isCompetencyAssessment &&
					!this.isNotMange && { label: '管理能力评估', index: 3, children: this.abilityItemList.map((v) => ({ label: v.title })) },
				this.isCompetencyAssessment && !this.isNotMange && { label: '胜任度评估', index: 4 },
				this.isQuality && { label: '潜力素质评估', index: 5, children: this.qualityItemList.map((v) => ({ label: v.title })) },
				this.isQuality && { label: '人才类型评估', index: 6 },
				{ label: '其他信息补充', index: 7 }
			].filter((v) => v);
		},
		query() {
			let query = {};

			if (this.$route.query.params) {
				query = JSON.parse(getUrlParams(this.$route.query.params));
			}
			return query;
		},
		// 批注修改 弹框 是否有按钮
		isEdit() {
			return (
				(this.query.type === 'edit' && !this.isAdminView) ||
				this.isAdminReviewed ||
				((this.isAdminComplete || this.planStatusToChange) && !this.isAdminView)
			);
		},
		// 页面表单状态 不允许编辑
		isView() {
			return this.formType === 'view';
		},
		// 评估页面
		isAssessment() {
			return (
				this.query.type === 'edit' &&
				// ['PendingAssessment', 'ManagementStation'].includes(this.query.pageName) &&
				['0', '1', '4'].includes(this.defaultData.status)
			);
		},
		// 有批注 和 修改按钮
		hasNoteBtns() {
			return ['2', '3', '4', '5', '6', '7'].includes(this.defaultData.status);
		},
		// 表单类型
		formType() {
			let type = 'edit';

			// 评估中 驳回的不能编辑
			if (['PendingAssessment'].includes(this.query.pageName)) {
				if (['4'].includes(this.defaultData.status)) {
					type = 'view';
				}
			}
			// 已处理页不能编辑 以及点击行进入
			if (['ProcessedAssessments', 'PendingReviewed', 'ProcessedReviewed'].includes(this.query.pageName) || this.query.type === 'view') {
				type = 'view';
			}
			if (this.isAdminPage) {
				type = 'view';
			}
			return type;
		},
		// 管理员端 无操作权
		isAdminView() {
			return this.isAdminPage && ['0', '1', '4', '6', '7'].includes(this.defaultData.status);
		},
		// 管理员端 复核
		isAdminReviewed() {
			const isAdminOrPm = this.isAdmin || this.isProjectManager;

			return this.isAdminPage && ['2', '5'].includes(this.defaultData.status) && this.query.type === 'edit' && isAdminOrPm;
		},
		// 管理员端 完成 且 进行中
		isAdminComplete() {
			const { status, planStatus } = this.defaultData;
			const isAdminOrPm = this.isAdmin || this.isProjectManager;

			return this.isAdminPage && ['3'].includes(status) && planStatus === '1' && this.query.type === 'edit' && isAdminOrPm;
		},
		// 员工 - 我复核的 待处理
		isReviewedPending() {
			return ['PendingReviewed'].includes(this.query.pageName) && ['2', '5'].includes(this.defaultData.status) && this.query.type === 'edit';
		},
		// 管理员
		isAdmin() {
			return this.defaultData.isAdmin;
		},
		// 项目经理
		isProjectManager() {
			return this.defaultData.isProjectManager;
		},
		// 计划状态已经处于“已完成”状态
		planStatusToChange() {
			const { planStatus } = this.defaultData;

			return ['2'].includes(planStatus) && this.isAdmin && this.isAdminPage && this.query.type === 'edit';
		},
		// 批注删除控制
		canDelComments() {
			return ['2', '3', '4', '5'].includes(this.defaultData.status);
		},
		// 胜任度
		isCompetencyAssessment() {
			return !['QualityAssessment'].includes(this.query.pageName) && this.query.evaluationType !== '2';
		},
		// 潜力素质评估
		isQuality() {
			return !['CompetencyAssessment'].includes(this.query.pageName) && this.query.evaluationType !== '1';
		},
		// 管理端页面
		isAdminPage() {
			return ['AllEvaluation', 'CompetencyAssessment', 'QualityAssessment', 'ReportByA'].includes(this.query.pageName);
		},
		showIsNew() {
			return this.isEdit && this.commentList.filter(({ isRead, status }) => isRead === 1 && status !== 0)?.length > 0;
		},
		// 非管理
		isNotMange() {
			return this.defaultData.initialPosition === '4';
		}
		// isMatchingDegree() {
		// 	return !['CompetencyAssessment', 'QualityAssessment'].includes(this.query.pageName);
		// }
	},
	watch: {},
	data() {
		const rules = {
			input: [{ required: true, message: '请输入', trigger: 'change' }],
			select: [{ required: true, message: '请选择', trigger: 'change' }]
		};

		return {
			activeNames: ['1', '2', '3', '4', '5', '6', '7', '8'],
			title: '评估详情',
			defaultData: {},
			factList: [],
			viewMore: false,
			showFooter: false,
			// 管理站位评估
			formItems3: [
				{
					// labelWidth: '0px',
					label: '',
					labelPosition: 'left',
					labelStyle: 'font-size: 16px;',
					dialogTypeId: '11',
					render: this.noteAndEidt.bind(this, '3'),
					skipId: 'oAfiDFoQn_3'
				},
				{
					label: '初始站位',
					key: 'initialPosition',
					labelWidth: '120px',
					type: 'select',
					groupId: 'rankName',
					span: 8,
					rules: rules.select,
					change: this.getCompetentByAbility,
					skipId: 'oAfiDFoQn_3'
				},
				{
					label: '实际站位',
					key: 'actualPosition',
					type: 'select',
					groupId: 'rankName',
					span: 8,
					rules: ({ initialPosition }) => (!['3', '4'].includes(initialPosition) && rules.select) || [],
					change: this.getCompetentByAbility,
					disabled: ({ initialPosition }) => initialPosition === '4',
					skipId: 'oAfiDFoQn_3'
				},
				{
					label: '站位匹配度',
					key: 'positionMatch',
					type: 'select',
					groupId: 'positionMatch',
					span: 8,
					rules: ({ initialPosition }) => (!['3', '4'].includes(initialPosition) && rules.select) || [],
					// change: this.getCompetentByAbility,
					disabled: true,
					skipId: 'oAfiDFoQn_3'
				},
				// { label: '关键区分事实描述', key: 'gjss', type: 'textarea', rows: 4 },
				{
					label: '关键区分事实描述',
					key: 'positionKeyDes',
					type: 'textarea',
					labelWidth: 'null',
					labelStyle: 'text-align:left',
					formItemClass: 'formItemTop',
					rules: ({ initialPosition }) => (!['3', '4'].includes(initialPosition) && rules.input) || [],
					disabled: ({ initialPosition }) => initialPosition === '4',
					skipId: 'oAfiDFoQn_3'
				},
				{
					label: '评价与建议',
					key: 'positionComment',
					type: 'ckeditor',
					formItemClass: 'formItemTop',
					labelWidth: 'null',
					templateType: 'rankName',
					labelRender: (f, item) => (
						<div style="width: 100%; text-align: left;display: inline-flex;align-items: center;justify-content: space-between;">
							<span>评价与建议</span>
							{['0', '1'].includes(this.defaultData.status) && !this.isView && (
								<el-button
									type="text"
									icon="el-icon-sort"
									style="cursor:pointer;z-index: 1"
									onclick={() => this.getTemplate(f, item)}
								>
									获取模板
								</el-button>
							)}
						</div>
					),
					skipId: 'oAfiDFoQn_3'
				}
			],
			// 胜任度评估
			formItems5: [
				{
					label: '',
					labelPosition: 'left',
					labelStyle: 'font-size: 16px;',
					dialogTypeId: '12',
					render: this.noteAndEidt.bind(this, '5')
				},
				{
					label: '是否胜任',
					key: 'rankLevel',
					type: 'select',
					groupId: 'rankLevel',
					disabled: ({ initialPosition }) => initialPosition !== '3' || initialPosition === '4'
				},
				{
					label: '胜任度评估综合说明',
					key: 'rankNote',
					type: 'textarea',
					labelWidth: 'null',
					labelStyle: 'text-align:left',
					formItemClass: 'formItemTop',
					rules: ({ initialPosition }) => (['0', '1', '2'].includes(initialPosition) && rules.input) || []
				}
			],
			// 人才类型评估
			formItems7: [
				{
					label: '',
					labelPosition: 'left',
					labelStyle: 'font-size: 16px;',
					dialogTypeId: '13',
					render: this.noteAndEidt.bind(this, '13'),
					skipId: 'oAfiDFoQn_7'
				},
				{
					label: '人才类型',
					key: 'talentType',
					type: 'select',
					groupId: 'talentType',
					span: 12,
					rules: rules.select,
					disabled: true,
					skipId: 'oAfiDFoQn_7'
				},
				{
					label: '任用建议',
					key: 'appointment',
					type: 'select',
					groupId: 'appointment',
					span: 12,
					rules: rules.select,
					skipId: 'oAfiDFoQn_7'
				},
				{
					label: '任用建议说明',
					key: 'appointmentNote',
					type: 'textarea',
					labelWidth: 'null',
					labelStyle: 'text-align:left',
					formItemClass: 'formItemTop',
					rules: rules.input,
					skipId: 'oAfiDFoQn_7'
				},
				{
					label: '综合评价',
					key: 'synthesizeAppraise',
					type: 'ckeditor',
					labelWidth: 'null',
					formItemClass: 'formItemTop',
					templateType: 'talentType',
					labelRender: (f, item) => (
						<div style="width: 100%; text-align: left;display: inline-flex;align-items: center;justify-content: space-between;">
							<span>评价与建议</span>
							{['0', '1'].includes(this.defaultData.status) && !this.isView && (
								<el-button
									type="text"
									icon="el-icon-sort"
									style="cursor:pointer;z-index: 1"
									onclick={() => this.getTemplate(f, item)}
								>
									获取模板
								</el-button>
							)}
						</div>
					),
					skipId: 'oAfiDFoQn_7'
				}
			],
			formItems8: [
				{
					label: '补充说明',
					key: 'remark',
					type: 'textarea',
					labelWidth: 'null',
					labelStyle: 'text-align:left',
					formItemClass: 'formItemTop',
					skipId: 'oAfiDFoQn_8'
				}
			],
			abilityItems: {
				title: {
					label: '',
					labelPosition: 'left',
					labelStyle: 'font-size: 16px;white-space:nowrap',
					formItemStyle: 'margin-top: 20px',
					render: this.noteAndEidt
				},
				evaluate: {
					label: '评价',
					labelWidth: '92px',
					key: '',
					type: 'select',
					groupId: 'abilityLevel',
					span: 12,
					rules: rules.select,
					change: this.getCompetentByAbility
				},
				level: {
					label: '评级',
					labelWidth: '92px',
					key: '',
					type: 'select',
					groupId: 'abilityGrade',
					span: 12,
					rules: rules.select,
					change: this.getTalentTypeByQuality
				},
				diffRemark: {
					label: '关键区分事实描述',
					key: '',
					type: 'textarea',
					labelWidth: 'null',
					labelStyle: 'text-align:left',
					formItemClass: 'formItemTop',
					rules: rules.input
				},
				propose: {
					label: '评价与建议',
					key: '',
					type: 'ckeditor',
					labelWidth: 'null',
					formItemClass: 'formItemTop',
					labelRender: (f, item) => (
						<div style="width: 100%; text-align: left;display: inline-flex;align-items: center;justify-content: space-between;">
							<span>评价与建议</span>
							{['0', '1'].includes(this.defaultData.status) && !this.isView && (
								<el-button
									type="text"
									icon="el-icon-sort"
									style="cursor:pointer;z-index: 1"
									onclick={() => this.getTemplate(f, item)}
								>
									获取模板
								</el-button>
							)}
						</div>
					)
				}
			},
			abilityItemList: [
				{
					title: '战略能力',
					children: [
						{ prop: 'strategyMatch', key: 'evaluate' },
						{ prop: 'strategy', key: 'level' },
						{ prop: 'strategyKeyDes', key: 'diffRemark' },
						{ prop: 'strategyComment', key: 'propose', templateType: 'fourAbilities' }
					]
				},
				{
					title: '变革能力',
					children: [
						{ prop: 'changeMatch', key: 'evaluate' },
						{ prop: 'changeExpert', key: 'level' },
						{ prop: 'changeKeyDes', key: 'diffRemark' },
						{ prop: 'changeComment', key: 'propose', templateType: 'fourAbilities' }
					]
				},
				{
					title: '高效体系构建能力',
					children: [
						{ prop: 'flowMatch', key: 'evaluate' },
						{ prop: 'flowExpert', key: 'level' },
						{ prop: 'flowKeyDes', key: 'diffRemark' },
						{ prop: 'flowComment', key: 'propose', templateType: 'fourAbilities' }
					]
				},
				{
					title: '人才队伍建设能力',
					children: [
						{ prop: 'advocateMatch', key: 'evaluate' },
						{ prop: 'employeeAdvocate', key: 'level' },
						{ prop: 'advocateKeyDes', key: 'diffRemark' },
						{ prop: 'advocateComment', key: 'propose', templateType: 'fourAbilities' }
					]
				}
			],
			qualityItemList: [
				{
					title: '主动性',
					children: [
						{ prop: 'initiative', key: 'level' },
						{ prop: 'initiativeKeyDes', key: 'diffRemark' },
						{ prop: 'initiativeComment', key: 'propose', templateType: 'fiveQualities' }
					]
				},
				{
					title: '概念思维',
					children: [
						{ prop: 'conceptThinking', key: 'level' },
						{ prop: 'conceptKeyDes', key: 'diffRemark' },
						{ prop: 'conceptComment', key: 'propose', templateType: 'fiveQualities' }
					]
				},
				{
					title: '影响力',
					children: [
						{ prop: 'influence', key: 'level' },
						{ prop: 'influenceKeyDes', key: 'diffRemark' },
						{ prop: 'influenceComment', key: 'propose', templateType: 'fiveQualities' }
					]
				},
				{
					title: '成就导向性',
					children: [
						{ prop: 'achievementGuide', key: 'level' },
						{ prop: 'guideKeyDes', key: 'diffRemark' },
						{ prop: 'guideComment', key: 'propose', templateType: 'fiveQualities' }
					]
				},
				{
					title: '坚韧性',
					children: [
						{ prop: 'hardiness', key: 'level' },
						{ prop: 'hardinessKeyDes', key: 'diffRemark' },
						{ prop: 'hardinessComment', key: 'propose', templateType: 'fiveQualities' }
					]
				}
			],
			activeId: '',
			tableVisible: false,
			dataList: [],
			tableCols: [],
			dataList1: [],
			tableCols1: [],
			tableTitle: '',
			tableTitle1: '',
			tableShowType: '',
			activeTab: '1',
			addNoteVisible: false,
			addTitle: '',
			reTitle: '',
			addNoteData: {},
			handleData: {},
			commentList: [],
			addCommentItems: [
				{
					label: '  ',
					key: 'comments',
					type: 'textarea',
					labelWidth: '0px',
					formItemClass: 'formItemLabel',
					rows: 8,
					autosize: false,
					rules: [{ required: true, message: '批注不能为空', trigger: 'blur' }]
				},
				{
					labelWidth: 'null',
					type: 'checkbox',
					key: 'isRead',
					text: '提醒评估人/复核人查看此批注'
				}
			],
			addFormItems: [],
			rules: {
				input: [{ required: true, message: '请输入', trigger: 'change' }],
				select: [{ required: true, message: '请选择', trigger: 'change' }]
			},
			tabsItem: {
				3: [
					{ label: '战略决策层', value: '1' },
					{ label: '战略执行层', value: '2' },
					{ label: '部门运营层', value: '3' }
				],
				4: [
					{ label: '主动性', value: '1' },
					{ label: '概念思维', value: '2' },
					{ label: '影响力', value: '3' },
					{ label: '成就导向', value: '4' },
					{ label: '坚韧性', value: '5' }
				]
			},
			recorderVisible: false
		};
	},

	mounted() {
		const { userId, planId } = this.query;

		this.getDetailInfo({ userId, planId }).then(() => {
			if (this.isAssessment) {
				clearInterval(timer);
				timer = setInterval(() => {
					this.submitForm(4, true);
				}, 60 * 2 * 1000);
			}
		});
	},

	methods: {
		// 保存接口
		saveForm(saveType, isAuto) {
			const params = { ...this.defaultData };

			// 非管理逻辑
			if (this.isNotMange) {
				const delKeys = [
					'strategyMatch',
					'strategy',
					'strategyKeyDes',
					'strategyComment',
					'changeMatch',
					'changeExpert',
					'changeKeyDes',
					'changeComment',
					'flowMatch',
					'flowExpert',
					'flowKeyDes',
					'flowComment',
					'advocateMatch',
					'employeeAdvocate',
					'advocateKeyDes',
					'advocateComment',
					'rankLevel',
					'rankNote'
				];

				delKeys.forEach((key) => {
					// this.$delete(params, key);
					// this.$delete(this.defaultData, key);
					this.$set(params, key, '');
					this.$set(this.defaultData, key, '');
				});
			}
			return this.$axios
				.post('/v1/internal/talent/saveReport', {
					request: { ...params, stars: this.factList, saveType }
				})
				.then((res) => {
					if (res.code) {
						return;
					}
					let msg = '操作成功';

					switch (saveType) {
						case 1:
							msg = '修改成功';
							this.$router.go(-1);
							break;
						case 2:
						case 6:
							msg = '驳回成功';
							this.$router.go(-1);
							break;
						case 4:
							msg = '保存成功';
							if (isAuto) {
								msg = '自动保存成功';
							}
							break;
						case 3:
						case 5:
						case 7:
							this.$router.go(-1);
							break;
						default:
							break;
					}
					this.$message.success(msg);
				});
		},
		// 提交
		submitForm(saveType, isAuto) {
			// saveType 1-管理员/项目经理确认修改 2-管理员/项目经理驳回修改 3-管理员/项目经理通过复核 4-评估人保存 5-评估人提交审核 6-复核人驳回修改 7-复核人通过复核
			if (saveType === 4) {
				this.saveForm(saveType, isAuto);
			} else {
				const { formItem1, formItem2, formItem3, formItem4, formItem5, formItem6, formItem7, formItemList = [] } = this.$refs;

				checkFormList([formItem1, ...formItemList, formItem2, formItem3, formItem4, formItem5, formItem6, formItem7].filter((v) => v))
					.then(() => {
						let msg = '';

						switch (saveType) {
							case 1:
								msg = '修改';
								break;
							case 4:
								msg = '保存';
								break;
							case 5:
								msg = '提交复核';
								break;
							case 6:
								msg = '驳回';
								break;
							case 7:
								msg = '复核通过';
								break;

							default:
								break;
						}
						this.$confirm(`您确认${msg}吗?`, '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {
							this.saveForm(saveType);
						});
					})
					.catch(({ err, items }) => {
						const errkey = Object.keys(err)?.[0];
						const { skipId } = items.find(({ key }) => key === errkey) || {};

						if (skipId) {
							this.jumpById('', `#${skipId}`);
						}
					});
			}
		},
		// 批注 编辑按钮
		noteAndEidt(_id, row, item) {
			if (this.hasNoteBtns) {
				const { dialogTypeId } = item;
				const {
					starTips, // 10
					positionTips, // 11
					strategyTips, // 1
					changeTips, // 2
					flowTips, // 3
					advocateTips, // 4
					competentTips, // 12
					initiativeTips, // 5
					conceptTips, // 6
					influenceTips, // 7
					guideTips, // 8
					hardinessTips, // 9
					typeTips // 13
				} = this.defaultData.tips;

				let tipsList = [];

				switch (dialogTypeId) {
					case '1':
						tipsList = strategyTips;
						break;
					case '2':
						tipsList = changeTips;
						break;
					case '3':
						tipsList = flowTips;
						break;
					case '4':
						tipsList = advocateTips;
						break;
					case '5':
						tipsList = initiativeTips;
						break;
					case '6':
						tipsList = conceptTips;
						break;
					case '7':
						tipsList = influenceTips;
						break;
					case '8':
						tipsList = guideTips;
						break;
					case '9':
						tipsList = hardinessTips;
						break;
					case '10':
						tipsList = starTips;
						break;
					case '11':
						tipsList = positionTips;
						break;
					case '12':
						tipsList = competentTips;
						break;
					case '13':
						tipsList = typeTips;
						break;

					default:
						break;
				}
				let tipObj = {};
				// 事实

				if (dialogTypeId === '10') {
					tipObj = (tipsList || []).find(({ starId }) => starId === row.id) || {};
				} else {
					tipObj = tipsList?.[0] || {};
				}
				const { commentNumber, commentTips, updateNumber, updateTips } = tipObj;
				const suffix = <span style="color:red;margin-left: 4px">(新)</span>;

				return (
					<div style="text-align:right" id={_id}>
						<el-badge value={commentNumber || 0} class="item" style="margin-right: 20px" type="primary">
							<el-button icon="el-icon-edit" size="small" onclick={() => this.handleNote({ type: item.dialogTypeId, row, tipObj })}>
								批注
								{commentTips && this.isEdit && suffix}
							</el-button>
						</el-badge>
						<el-badge value={updateNumber || 0} class="item" type="primary">
							<el-button icon="el-icon-edit" size="small" onclick={() => this.handleChange({ type: item.dialogTypeId, row, tipObj })}>
								修改
								{updateTips && this.isEdit && suffix}
							</el-button>
						</el-badge>
					</div>
				);
			}
			return <div id={_id}></div>;
		},
		// 隐藏展示
		handleTitle(i) {
			if (this.activeNames.includes(i)) {
				this.activeNames = this.activeNames.filter((val) => val !== i);
			} else {
				this.activeNames.push(i);
			}
		},
		// 锚点跳转
		jumpById(e, id) {
			document.querySelector(id).scrollIntoView({
				behavior: 'smooth'
			});
			this.activeId = id;
		},
		// 获取详情
		getDetailInfo(data) {
			const loading = Loading.service({ target: '.formContent' });

			return this.$axios.post('/v1/internal/talent/getReportByPlanAndUserInfo', { request: data }).then((res) => {
				loading.close();
				if (res.code) {
					return;
				}
				const { stars } = res;

				if (stars?.length) {
					this.factList = [...stars];
				}
				const obj = {};
				// eslint-disable-next-line guard-for-in

				for (const k in res) {
					if (res[k] || res[k] === 0) {
						obj[k] = res[k];
					}
				}
				this.defaultData = { ...obj };
			});
		},
		// 展示说明
		showExplain(type) {
			this.tableShowType = type;
			switch (type) {
				case 1:
					this.dataList = fourData;
					this.tableCols = fourManCols;
					this.dataList1 = wdData;
					this.tableCols1 = wdCols;
					this.tableTitle = '四层管理站位';
					this.tableTitle1 = '评估维度';
					break;
				case 2:
					this.dataList = pjData;
					this.tableCols = pjCols;
					// this.dataList1 = srData;
					// this.tableCols1 = srCols;
					this.tableTitle = '四项能力评级标准';
					// this.tableTitle1 = '四项能力胜任标准';
					break;
				case 3:
					this.dataList = quaData[Number(this.activeTab) - 1];
					this.tableCols = srdCols;
					this.tableTitle = '现岗位胜任度评价标准';
					break;
				case 4:
					this.dataList = [qlData[Number(this.activeTab) - 1]];
					this.tableCols = qlCols;
					this.tableTitle = '五项素质评级标准';
					break;
				case 5:
					this.dataList = rcData;
					this.tableCols = rcCols;
					this.tableTitle = '人才类型对照（最低标准）';
					break;

				default:
					break;
			}

			this.tableVisible = true;
		},
		handleTabs() {
			switch (this.tableShowType) {
				case 3:
					this.dataList = quaData[Number(this.activeTab) - 1];
					break;
				case 4:
					this.dataList = [qlData[Number(this.activeTab) - 1]];
					break;

				default:
					break;
			}
		},
		handleAdd() {
			this.$set(this.addNoteData, 'isRead', false);
			this.addNoteVisible = true;
		},
		// 修改批注状态
		handleIsRead({ id }) {
			this.$axios
				.post('/v1/internal/talent/changeReportProcessingStatus', { request: { id, type: this.handleData.type } })
				.then((res) => {
					if (res.code) {
						return;
					}
					this.$message.success('修改成功');
					this.getCommentsList();
					const { userId, planId } = this.query;

					this.getDetailInfo({ userId, planId });
				})
				.catch(() => {});
		},
		// 回退
		comeBackTo({ id }) {
			this.$confirm('是否确定退回此版本?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			})
				.then(() => {
					this.$axios
						.post('/v1/internal/talent/modifyReportVersionRollback', { request: { id, type: this.handleData.type } })
						.then((res) => {
							if (res.code) {
								return;
							}
							this.$message.success('操作成功');
							const { userId, planId } = this.query;

							this.getDetailInfo({ userId, planId });
							// this.getCommentsList();
							this.addNoteVisible = false;
						})
						.catch(() => {});
				})
				.catch((error) => {
					console.log(error);
				});
		},
		// 删除批注
		deleteComment({ id }) {
			this.$confirm('是否确定删除?', '删除提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			})
				.then(() => {
					this.$axios
						.post('/v1/internal/talent/deleteReportComment', { request: { id, type: this.handleData.type } })
						.then((res) => {
							if (res.code) {
								return;
							}
							this.$message.success('删除成功');
							// this.getCommentsList();
							const { userId, planId } = this.query;

							this.getDetailInfo({ userId, planId });
							this.commentList = this.commentList.filter(({ id: rid }) => rid !== id);
						})
						.catch(() => {});
				})
				.catch((error) => {
					console.log(error);
				});
		},
		getCommentsList() {
			const { type, row, dialogType } = this.handleData;
			const params = { type, reportId: this.defaultData.id };

			if (type === '10') {
				params.starId = row.id;
			}
			let url = '/v1/internal/talent/getReportComment';

			if (dialogType === 'change') {
				url = '/v1/internal/talent/getReportModify';
			}
			const loading = Loading.service({ target: '.dialogLoading' });

			this.$axios
				.post(url, { request: params })
				.then((res) => {
					loading.close();
					if (res.code) {
						return;
					}
					this.commentList = res.list;
				})
				.catch(() => {
					loading.close();
				});
		},
		// 点击批注
		handleNote(data) {
			this.handleData = { ...data, dialogType: 'note' };
			this.addTitle = '批注记录';
			this.reTitle = '新增批注';
			this.viewMore = false;
			this.addFormItems = this.addCommentItems;
			this.$set(this.addNoteData, 'isRead', false);
			this.getCommentsList();
			if (this.isEdit) {
				this.showFooter = true;
			}
			this.addNoteVisible = true;
		},
		// 点击修改
		handleChange(data) {
			this.handleData = { ...data, dialogType: 'change' };
			this.addTitle = '修改记录';
			this.reTitle = '新增修改记录';
			this.viewMore = false;
			this.addNoteData = { ...data.row };
			this.$set(this.addNoteData, 'isRead', false);
			const checkItem = {
				labelWidth: 'null',
				type: 'checkbox',
				key: 'isRead',
				text: '提醒评估人/复核人查看此修改'
			};

			switch (data.type) {
				case '1': // 管理能力
					this.addFormItems = this.formItems4.slice(1, 5).concat(checkItem);
					break;
				case '2':
					this.addFormItems = this.formItems4.slice(6, 10).concat(checkItem);
					break;
				case '3':
					this.addFormItems = this.formItems4.slice(11, 15).concat(checkItem);
					break;
				case '4':
					this.addFormItems = this.formItems4.slice(16, 20).concat(checkItem);
					break;
				case '5':
					this.addFormItems = this.formItems6.slice(1, 4).concat(checkItem);
					break;
				case '6':
					this.addFormItems = this.formItems6.slice(5, 8).concat(checkItem);
					break;
				case '7':
					this.addFormItems = this.formItems6.slice(9, 12).concat(checkItem);
					break;
				case '8':
					this.addFormItems = this.formItems6.slice(13, 16).concat(checkItem);
					break;
				case '9':
					this.addFormItems = this.formItems6.slice(17, 20).concat(checkItem);
					break;
				case '10':
					this.addFormItems = this.formItems2(1).slice(1).concat(checkItem);
					break;
				case '11':
					this.addFormItems = this.formItems3.slice(1).concat(checkItem);
					break;
				case '12':
					this.addFormItems = this.formItems5.slice(1).concat(checkItem);
					break;
				case '13':
					this.addFormItems = this.formItems7.slice(1).concat(checkItem);
					break;

				default:
					break;
			}
			this.getCommentsList();
			if (this.isEdit) {
				this.showFooter = true;
			}
			this.addNoteVisible = true;
		},
		// 添加批注 或修改
		addNoteSubmit(form) {
			form.validate((valid) => {
				if (valid) {
					const { type, row, dialogType } = this.handleData;
					const { isRead, comments } = this.addNoteData;

					const params = { type, isRead: isRead ? '1' : '0', comments, reportId: this.defaultData.id };

					const loading = Loading.service({ target: '.dialogLoading' });

					if (dialogType === 'change') {
						this.$delete(params, 'comments');
						this.addFormItems.slice(0, -1).forEach(({ key }) => {
							params[key] = this.addNoteData[key];
						});
						if (type === '10') {
							params.starId = row.id;
						}
						return this.$axios
							.post('/v1/internal/talent/modifyReport', { request: params })
							.then((res) => {
								loading.close();
								if (res.code) {
									return;
								}

								this.handleData = {};
								this.addNoteData = { ...this.handleData.row };
								this.addNoteVisible = false;
								this.$message.success('修改成功');
								const { userId, planId } = this.query;

								this.getDetailInfo({ userId, planId });
							})
							.catch(() => {
								loading.close();
							});
					}
					// 事实
					if (type === '10') {
						params.starId = row.id;
					}

					this.$axios
						.post('/v1/internal/talent/addReportComment', { request: params })
						.then((res) => {
							loading.close();
							if (res.code) {
								return;
							}

							this.handleData = {};
							this.addNoteData = {};
							this.addNoteVisible = false;
							this.$message.success('新增批注成功');
							const { userId, planId } = this.query;

							this.getDetailInfo({ userId, planId });
						})
						.catch(() => {
							this.addNoteVisible = false;
							loading.close();
						});
				}
			});
		},
		// 计算 人才类型
		getTalentTypeByQuality() {
			const { initiative, conceptThinking, influence, achievementGuide, hardiness } = this.defaultData;

			if (initiative && conceptThinking && influence && achievementGuide && hardiness) {
				const [n1, n2, n3, n4, n5] = [
					Number(initiative),
					Number(conceptThinking),
					Number(influence),
					Number(achievementGuide),
					Number(hardiness)
				];

				let talentType = '';

				if (n1 >= 3 && n2 >= 2 && n3 >= 2 && n4 >= 3 && n5 >= 2) {
					talentType = '0';
				} else if (n1 >= 2 && n2 >= 2 && n3 >= 2 && n4 >= 2 && n5 >= 2) {
					talentType = '1';
				} else if (n1 >= 1 && n2 >= 1 && n3 >= 1 && n4 >= 1 && n5 >= 2) {
					talentType = '2';
				} else if (n1 >= 1 && n2 >= 1 && n3 >= 1 && n4 >= 1 && n5 >= 1) {
					talentType = '3';
				} else {
					talentType = '4';
				}
				this.$set(this.defaultData, 'talentType', talentType);
			}
		},
		// 计算 胜任
		getCompetentByAbility() {
			const { initialPosition, positionMatch, changeMatch, strategyMatch, flowMatch, advocateMatch, actualPosition } = this.defaultData;

			// 初始 实际 算出 匹配度
			if (initialPosition && actualPosition) {
				const [n1, n2] = [Number(initialPosition), Number(actualPosition)];

				this.$set(this.defaultData, 'positionMatch', n1 === n2 ? '1' : '0');
			} else if (!initialPosition || !actualPosition) {
				this.$set(this.defaultData, 'positionMatch', '');
			}
			// 编辑 管理站位评估
			if (this.addNoteData.initialPosition) {
				const { initialPosition, actualPosition } = this.addNoteData;

				if (initialPosition && actualPosition) {
					this.$set(this.addNoteData, 'positionMatch', initialPosition === actualPosition ? '1' : '0');
				} else if (!initialPosition || !actualPosition) {
					this.$set(this.addNoteData, 'positionMatch', '');
				}
				// 非管理层
				if (this.addNoteData.initialPosition === '4') {
					this.$set(this.addNoteData, 'actualPosition', '');
					this.$set(this.addNoteData, 'positionMatch', '');
					this.$set(this.addNoteData, 'positionKeyDes', '');
				}
			}
			// 初始站位 为 部门运营层
			if (initialPosition && positionMatch && flowMatch && advocateMatch) {
				const [n1, n2, n3, n4, n5, n6] = [
					Number(initialPosition),
					Number(positionMatch),
					Number(changeMatch), // 变革
					Number(strategyMatch), // 战略
					Number(flowMatch),
					Number(advocateMatch)
				];

				let isCompetent = '';

				if (n1 === 0) {
					if (n2 === 1 && n3 === 2 && n4 === 2 && n5 === 2 && n6 === 2) {
						// 完全胜任
						isCompetent = '1';
					} else if (n2 === 1 && n3 >= 1 && n4 >= 1 && n5 >= 1 && n6 >= 1) {
						// 基本胜任
						isCompetent = '2';
					} else {
						// 不胜任
						isCompetent = '3';
					}
				} else if (n1 === 1) {
					if (n2 === 1 && n3 >= 1 && n4 === 2 && n5 === 2 && n6 === 2) {
						// 完全胜任
						isCompetent = '1';
					} else if (n2 === 1 && n3 >= 0 && n4 >= 1 && n5 >= 1 && n6 >= 1) {
						// 基本胜任
						isCompetent = '2';
					} else {
						// 不胜任
						isCompetent = '3';
					}
				} else if (n1 === 2) {
					if (n2 === 1 && n5 === 2 && n6 === 2) {
						// 完全胜任
						isCompetent = '1';
					} else if (n2 === 1 && n5 >= 1 && n6 >= 1) {
						// 基本胜任
						isCompetent = '2';
					} else {
						// 不胜任
						isCompetent = '3';
					}
				}
				if (isCompetent) {
					this.$set(this.defaultData, 'rankLevel', isCompetent);
				}
			} else {
				this.$set(this.defaultData, 'rankLevel', '');
			}
			// 非管理层
			if (initialPosition === '4') {
				this.$set(this.defaultData, 'actualPosition', '');
				this.$set(this.defaultData, 'positionMatch', '');
				this.$set(this.defaultData, 'positionKeyDes', '');
			}
		},
		handleView() {
			// this.$router.push({
			// 	name: 'EvaluationReportView',
			// 	query: { params: setUrlParams({ pageName: this.pageName }) }
			// });
			window.open(
				`/#/evaluationReportView?params=${setUrlParams({
					pageName: this.pageName,
					id: this.defaultData.id,
					evaluationType: this.query.evaluationType
				})}`
			);
		},
		getTemplate(formModel, item) {
			const { initialPosition, positionMatch, rankLevel, talentType } = formModel;
			const { templateType, _index, levelKey, key } = item;

			let [key1, key2] = ['', ''];

			switch (templateType) {
				case 'fiveQualities':
					key1 = _index + 5;
					key2 = formModel[levelKey];
					break;
				case 'fourAbilities':
					key1 = _index + 1;
					key2 = formModel[levelKey];
					break;
				case 'rankLevel':
					key1 = rankLevel;
					key2 = positionMatch;
					break;
				case 'rankName':
					key1 = initialPosition;
					key2 = positionMatch;
					break;
				case 'talentType':
					key1 = talentType;
					break;

				default:
					break;
			}
			this.$axios
				.post('/v1/talent/reportTemplate/matchingTemplate', { request: { type: templateType, key1, key2, reportId: this.defaultData.id } })
				.then((res) => {
					if (typeof res === 'string') {
						this.$set(this.defaultData, key, res);
					}
				});
		},
		// 计算合并
		spanMethod({ rowIndex, columnIndex }) {
			// 胜任度
			if (this.tableShowType === 3) {
				if (rowIndex === 2 && columnIndex === Number(this.activeTab) + 2) {
					return [1, 5 - Number(this.activeTab)];
				}
			}
		}
	},
	beforeDestroy() {
		clearInterval(timer);
	}
};
</script>

<style lang="scss">
.evaluationCenterDetail {
	background-color: #fff;
	.titlebox {
		margin: 0;
		padding: 20px 60px;
		border-bottom: 1px solid #efefef;
	}
	.formTitle {
		background: #f1f8fe;
		text-align: left;
		font-size: 16px;
		height: 40px;
		line-height: 40px;
		padding: 0 20px;
		// margin-bottom: 20px;
	}
	.formContent {
		padding: 30px 60px;
		height: calc(100vh - 66px);
		overflow-y: auto;

		.addFunctions {
			display: inline-flex;
			align-items: center;
			width: 100%;
			color: #409eff;
			cursor: pointer;
			margin: 0 0 30px 0;
			justify-content: center;
		}

		.formIteWrap {
			padding: 20px;
			border: 1px dashed #bfbfbf;
			margin: 20px 40px;
		}
		.aside_anchor {
			position: absolute;
			top: 100px;
			.anchor_line {
				position: absolute;
				inset-block-start: 0;
				inset-inline-start: 0;
				height: 100%;
				&::before {
					position: relative;
					display: block;
					width: 2px;
					height: 100%;
					margin: 0 auto;
					background-color: rgba(5, 5, 5, 0.06);
					content: ' ';
				}
			}

			.anchor_text_box {
				font-size: 12px;
				color: #333;
				.anchor_text {
					padding-block: 4px;
					padding-inline: 16px 0;
				}
			}
		}
		.recorderWrap {
			position: fixed;
			right: 80px;
			bottom: 30vh;
			z-index: 1;
		}
	}
	.formItemTop {
		// display: inline-flex;
		// flex-direction: column;
		position: relative;
		width: 100%;
		padding-left: 33px;
		margin-bottom: 0px;
		label {
			width: 100%;
			display: inline-flex;
			align-items: center;
		}
	}
	.formItemLabel {
		label {
			height: 0;
		}
	}
	.formFooter {
		text-align: center;
		padding: 20px 0;
		border-top: 1px solid #efefef;
		position: fixed;
		bottom: 0;
		width: 100%;
		background-color: #fff;
	}
	.noBorder {
		textarea {
			border: none;
			resize: none;
		}
	}
}
</style>

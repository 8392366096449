// 四层管理
const fourManCols = [
	{ label: '层级', key: 'level' },
	{ label: '关键区分', key: 'keyDiff' },
	{ label: '关键事实', key: 'KeyFacts', wrap: true }
];
const fourData = [
	{
		level: '战略决策层',
		keyDiff: '战略方向的制定者',
		KeyFacts: `
        ● 某一业务模式的创立者；
        ● 从纷繁复杂的业务环境中找到突破口，清楚定位精准客户、带领团队实现产品打造、确保业务成功交付；
        ● 关键战略资源的获得。
    `
	},
	{
		level: '战略执行层',
		keyDiff: '战略执行者',
		KeyFacts: `
        ● 新业务或者传统业务关键问题突破者；
        ● 在既有战略方向的指引下，从零开始组建团队、找到客户、成交第一单、关注竞争对手；
        ● 聚焦核心业务的内外部成功因素。
    `
	},
	{
		level: '部门运营层',
		keyDiff: '部门全面运营者',
		KeyFacts: `
        ● 负责某一部门或职能的全面经营管理，确保组织高效运转；
        ● 负责日常管理，如费用、收入、流程、人才选拔、人员培养、绩效评价、业务改进，对部门业绩负责。
    `
	},
	{
		level: '业务执行层',
		keyDiff: '执行实操者',
		KeyFacts: `
        ● 业务尖兵，是该岗位的业务能手，能在专业上有效解决问题、指导团队工作；
        ● 带领一个小团队完成具体的业务日常运作，擅长单点突破。
    `
	}
];
// 评估维度
const wdCols = [
	{ label: '评价', key: 'evaluate' },
	{ label: '判断依据	', key: 'judge' }
];
const wdData = [
	{
		evaluate: '站位正确',
		judge: '评估对象所处站位与岗位层级一致。'
	},
	{
		evaluate: '站位不正确',
		judge: '评估对象所处站位与岗位层级不一致'
	},
	{
		evaluate: '站位越位',
		judge: '（总经理干主管的事“一杆子管到底”；主管想着老板的事情；这是一种站位极端不正确的情况）'
	},
	{
		evaluate: '站位缺位',
		judge: '指组织序列某一层站位人员一个都没有的情况（不对个人评价）'
	}
];
// 四项能力评级标准
const pjCols = [
	{
		label: '战略能力',
		key: 'zlnl',
		children: [
			{
				label: '能力等级',
				key: 'capabilityLevel1'
			},
			{
				label: '关键标准',
				key: 'keyCriteria1',
				wrap: true
			}
		]
	},
	{
		label: '变革能力',
		key: 'bgnl',
		children: [
			{
				label: '能力等级',
				key: 'capabilityLevel2'
			},
			{
				label: '关键标准',
				key: 'keyCriteria2',
				wrap: true
			}
		]
	},
	{
		label: '高效体系构建能力',
		key: 'gxtx',
		children: [
			{
				label: '能力等级',
				key: 'capabilityLevel3'
			},
			{
				label: '关键标准',
				key: 'keyCriteria3',
				wrap: true
			}
		]
	},
	{
		label: '人才队伍建设能力',
		key: 'rcdw',
		children: [
			{
				label: '能力等级',
				key: 'capabilityLevel4'
			},
			{
				label: '关键标准',
				key: 'keyCriteria4',
				wrap: true
			}
		]
	}
];
const pjData = [
	{
		capabilityLevel1: '3级',
		keyCriteria1: `制定战略
        能够深刻洞察市场机会或变化，主导制定企业或业务的发展战略，有效牵引企业发展。`,
		capabilityLevel2: '3级',
		keyCriteria2: `制定变革
        能够根据战略需求，明确企业的变革方向，制定企业或业务的变革目标，选定变革推动团队。`,
		capabilityLevel3: '3级',
		keyCriteria3: `定义系统
        能够基于对战略的深刻洞察，结合企业的实际情况，定义“从客户到客户”的端到端高效体系，支撑业务持续高增长。`,
		capabilityLevel4: '3级',
		keyCriteria4: `构建梯队
        能够基于组织长期战略目标构建人才梯队，充分发挥人才价值，以人才引领、驱动战略升级或实现战略突破。`
	},
	{
		capabilityLevel1: '2级',
		keyCriteria1: `执行战略
        具备良好的战略解码能力，能够制定战略的有效实施路径，承接并推进战略目标达成。`,
		capabilityLevel2: '2级',
		keyCriteria2: `执行变革
        能深刻的理解变革需求，根据实际情况制定变革策略和实施方案，确保变革目标落地达成。`,
		capabilityLevel3: '2级',
		keyCriteria3: `搭建体系
        能够深刻理解体系建设需求，有效主导搭建体系框架、构建体系流程、制定体系标准与制度，并持续迭代优化。`,
		capabilityLevel4: '2级',
		keyCriteria4: `能打胜仗
        能够基于战略诉求规划人才团队结构，抓住业务核心需求，引进关键人才，带出一支能打胜仗的队伍。`
	},
	{
		capabilityLevel1: '1级',
		keyCriteria1: `理解战略
        能清晰的理解企业战略设计逻辑与内容，能有效指导团队的重点工作开展。`,
		capabilityLevel2: '1级',
		keyCriteria2: `理解变革
        能清晰理解变革逻辑与要求，能有效指导团队理解变革，参与变革活动。`,
		capabilityLevel3: '1级',
		keyCriteria3: `制定标准
        能够参与体系建设，通过总结或提取业务经验，转化为具体操作流程，形成可复制的标准或制度，有效支持业务开展。`,
		capabilityLevel4: '1级',
		keyCriteria4: `组建团队
        能够按照业务需求识别可胜任人才，能吸引人才，组建团队并支持业务工作开展。`
	},
	{
		capabilityLevel1: '0级',
		keyCriteria1: `战略缺失
        不能正确理解战略与自身工作的关联性，或不清楚战略。`,
		capabilityLevel2: '0级',
		keyCriteria2: `变革缺失
        不能理解变革，缺乏变革意识，抵触变化。`,
		capabilityLevel3: '0级',
		keyCriteria3: `不能构建
        对具体业务理解不深入，无法总结经验，不能对体系建设形成有效贡献。`,
		capabilityLevel4: '0级',
		keyCriteria4: `不会识人
        不能根据岗位需求准确识别人才，无法组建团队支撑业务工作正常开展。`
	}
];
// 四项能力胜任标准
const srCols = [
	{ label: ' ', key: 'level' },
	{ label: '战略能力', key: 'zlnl' },
	{ label: '变革能力', key: 'bgnl' },
	{ label: '高效体系构建能力', key: 'gxtx' },
	{ label: '人才队伍建设能力', key: 'rcdw' }
];
const srData = [
	{ level: '决策层', zlnl: '3级', bgnl: '3级', gxtx: '3级', rcdw: '3级' },
	{ level: '总监层', zlnl: '2级', bgnl: '2级', gxtx: '2级', rcdw: '2级' },
	{ level: '经理层', zlnl: '1级', bgnl: '1级', gxtx: '2级', rcdw: '2级' },
	{ level: '主管层', zlnl: '1级', bgnl: '1级', gxtx: '1级', rcdw: '1级' }
];
// 胜任度
const srdCols = [
	{ label: '现岗胜任度', key: 'jobCompetence' },
	{ label: '胜任度描述', key: 'competenceRemark' },
	{ label: '站位匹配度要求（底线）', key: 'minMatchingDegree' },
	{
		label: '管理能力要求（底线）',
		key: 'minManagementAbility',
		children: [
			{ label: '变革能力', key: 'change' },
			{ label: '战略能力', key: 'strategy' },
			{ label: '体系构建能力', key: 'systemConstruction' },
			{ label: '人才队伍建设能力', key: 'teamBuilding' }
		]
	}
];
const quaData = [
	[
		{
			jobCompetence: '完全胜任',
			competenceRemark: '完全具备该层级典型事件的描述，且有明显的结果显现',
			minMatchingDegree: '匹配',
			change: '具备',
			strategy: '具备',
			systemConstruction: '具备',
			teamBuilding: '具备'
		},
		{
			jobCompetence: '基本胜任',
			competenceRemark: '有部分，但关键方面所表现的程度存在不足',
			minMatchingDegree: '匹配',
			change: '不足',
			strategy: '不足',
			systemConstruction: '不足',
			teamBuilding: '不足'
		},
		{
			jobCompetence: '暂不胜任',
			competenceRemark: '指完全不具备该层级描述中的典型事件',
			minMatchingDegree: '不匹配',
			change: '任意一项缺失'
			// strategy: '具备',
			// systemConstruction: '具备',
			// teamBuilding: '具备'
		}
	],
	[
		{
			jobCompetence: '完全胜任',
			competenceRemark: '完全具备该层级典型事件的描述，且有明显的结果显现',
			minMatchingDegree: '匹配',
			change: '不足',
			strategy: '具备',
			systemConstruction: '具备',
			teamBuilding: '具备'
		},
		{
			jobCompetence: '基本胜任',
			competenceRemark: '有部分，但关键方面所表现的程度存在不足',
			minMatchingDegree: '匹配',
			change: '缺失',
			strategy: '不足',
			systemConstruction: '不足',
			teamBuilding: '不足'
		},
		{
			jobCompetence: '暂不胜任',
			competenceRemark: '指完全不具备该层级描述中的典型事件',
			minMatchingDegree: '不匹配',
			change: '缺失',
			strategy: '任意一项缺失'
			// systemConstruction: '具备',
			// teamBuilding: '具备'
		}
	],
	[
		{
			jobCompetence: '完全胜任',
			competenceRemark: '完全具备该层级典型事件的描述，且有明显的结果显现',
			minMatchingDegree: '匹配',
			change: '缺失',
			strategy: '缺失',
			systemConstruction: '具备',
			teamBuilding: '具备'
		},
		{
			jobCompetence: '基本胜任',
			competenceRemark: '有部分，但关键方面所表现的程度存在不足',
			minMatchingDegree: '匹配',
			change: '缺失',
			strategy: '缺失',
			systemConstruction: '不足',
			teamBuilding: '不足'
		},
		{
			jobCompetence: '暂不胜任',
			competenceRemark: '指完全不具备该层级描述中的典型事件',
			minMatchingDegree: '不匹配',
			change: '缺失',
			strategy: '缺失',
			systemConstruction: '任意一项缺失'
			// systemConstruction: '具备',
			// teamBuilding: '具备'
		}
	]
];
const srdData = [
	{
		evaluationItems: '完全胜任',
		standard: `决策层：站位匹配，且能力达到3333
        总监层：站位匹配，且能力达到2222或以上
        经理层：站位匹配，且能力达到1122或以上
        主管层：站位匹配，且能力达到1111或以上					`
	},
	{
		evaluationItems: '基本胜任',
		standard: `决策层：站位匹配，且能力达到3222或以上
    总监层：站位匹配，且能力达到2112或以上
    经理层：站位匹配，且能力达到1111或以上
    主管层：站位匹配，且能力达到0011或以上					`
	},
	{
		evaluationItems: '暂不胜任',
		standard: `管理者站位不匹配；
    站位匹配，但能力低于以上情况者。					`
	}
];

// 五项素质评级标准
const qlCols = [
	{ label: '整体描述', key: 'remark', wrap: true },
	{ label: '0级', key: 'zero', wrap: true },
	{ label: '1级', key: 'one', wrap: true },
	{ label: '2级', key: 'two', wrap: true },
	{ label: '3级', key: 'three', wrap: true }
];
const qlData = [
	{
		remark: `主动性是指个人在工作中不惜投入较多的精力，善于发现和创造新的机会，提前预计到事件发生的可能性，并有计划地采取行动提高工作绩效、避免问题的发生、或创造新的机遇。
    提示：主动性≠态度积极却并无建设性的行为	`,
		zero: `不主动  
        不会自觉地完成工作任务，需要他人的督促。不能提前计划或思考问题，直到问题发生后才能意识到事情的严重性。	`,
		one: `主动行动  
        主动性一级的人能主动行动，自觉投入更多的努力去工作，不需要别人督促，只要分配给他的工作，他就会自觉地投入时间去做。	`,
		two: `主动思考 快速行动  
        及时发现某种机会和问题并快速做出反应。主动性二级的人不光能快速自觉地工作，而且会主动进行思考，预判某一种情况，然后采取相应的行动。	`,
		three: `未雨绸缪 提前行动  
        主动性三级的人不会等着问题发生，而是会未雨绸缪，提前行动，规避问题甚至创造出机会来。	`
	},
	{
		remark: `概念思维是一种识别表面上无明显关联的事情之间的内部联系和本质特征的能力。在面对复杂问题或现象时，尽管信息不全，能够迅速抓住问题要害或敏锐地发现潜在机会，高屋建瓴，一针见血、一语道破。
        提示：概念思维≠归纳演绎能力	`,
		zero: `想不清看不明  
        不能准确而周密地考虑事物发生的原因，或者不能根据已有的经验或知识对当前所面临的问题做出正确的判断。	`,
		one: `简单类比  
        能根据以往的成功经验，通过有限的信息对事物进行全面的认识或判断，能够辨别当前情况同过去的经历之间的相同之处和不同之处，在新的条件下成功复制以往经验。	`,
		two: `触类旁通  
        通过对事物发展规律的总结与洞察，提炼出一套底层的方法逻辑，能够透过复杂的信息对眼前的问题进行整体分析和处理，抓住关键问题。对于陌生的知识或领域，能够快速穿透，跨越认知壁垒。	`,
		three: `深入浅出  
        能够洞察复杂事物的底层本质，并将复杂的情况或观点用简单、通俗的方式清晰有效地表达出来。能够用更加简洁的方法阐述繁复的知识和现象。	`
	},
	{
		remark: `影响力是施加影响的能力，是一种试图去说服、劝服、影响或给他人（个人或组织）留下印象来让他们赞同或支持说话者的观点的行为特征。主观上必须具有让他人接受自己的想法的愿望，客观上个人并没有权利将自己的意愿强加给别人。	`,
		zero: `不能影响  
        不能清楚地表达自己要讲的内容或不能说服别人听从自己的观点。	`,
		one: `直接说服  
        通过向别人讲述理由、证据、事实、数据等方式，直接说服别人。或者给别人留下好的印象，以便获得别人的认可。	`,
		two: `设身处地  
        能换位思考，站在他人的角度，用他人的话去解决他的问题，或同时采取多种方式影响他人。	`,
		three: `综合策略  
        运用复杂的策略影响他人或通过微妙的幕后操作使别人接受自己的观点。	`
	},
	{
		remark: `成就导向是指个人具有成功完成任务或在工作中追求卓越的愿望。个人希望出色地完成任务，愿意从事具有挑战性的任务。这种人在工作中有强烈地表现自己的能力的愿望，不断地为自己设立更高的标准，努力不懈地追求事业上的进步。	`,
		zero: `安于现状  
        安于现状，不追求个人技术或专业修养方面的进步。	`,
		one: `追求更好  
        努力将工作做得更好，或达到某个优秀的标准。	`,
		two: `挑战目标  
        为自己设立富有挑战性的目标，并为达到这些目标而付诸行动。	`,
		three: `敢冒经过评估的风险 
        在仔细权衡代价和利益、利与弊的基础上作出某种决策，为了获得更大的成功，敢于冒险。	`
	},
	{
		remark: `坚韧性是指能够在艰苦或不利情况下，克服外部和自身的困难，坚持完成所从事的任务。具有强坚韧性的人能够在受到挫折的情况下控制自己的不良情绪，使自己不会采取消极的行动；面对他人的敌意时保持冷静和稳定的情绪状况；能够忍受艰苦的工作条件和较大的压力，使其工作业绩不受外界压力、挫折和个人消极情绪的干扰。	`,
		zero: `扛不住 
        不能控制自己的情绪，经受不了批评、挫折和压力，稍微遇到点压力就选择放弃。	`,
		one: `压不垮  
        在工作中能够保持良好的体能和稳定的情绪状态；面对挫折时能够克制自己的消极情绪（愤怒、焦急、失望等），努力坚持，不言放弃。	`,
		two: `干得成  
        不仅能在艰苦的环境中顶住压力，重要的是一定能把事做成，克服困难后会带来好的结果。	`,
		three: `解除掉 
        能通过建设性的方式消除他人的敌意或保证自己情绪的稳定，不受制于压力的影响，能把压力解除，顺利完成任务。	`
	}
];
// 人才类型对照
const rcCols = [
	{ label: '人才类型', key: 'talentType' },
	{ label: '主动性', key: 'initiative' },
	{ label: '概念思维	', key: 'concept' },
	{ label: '影响力', key: 'effect' },
	{ label: '成就导向', key: 'achievement' },
	{ label: '坚韧性', key: 'toughness' }
];
const rcData = [
	{ talentType: '企业家型人才', initiative: '3级', concept: '2级', effect: '2级', achievement: '3级', toughness: '2级' },
	{ talentType: '开创型人才', initiative: '2级', concept: '2级', effect: '2级', achievement: '2级', toughness: '2级' },
	{ talentType: '守成型人才', initiative: '1级', concept: '1级', effect: '1级', achievement: '1级', toughness: '2级' },
	{ talentType: '执行型人才', initiative: '1级', concept: '1级', effect: '1级', achievement: '1级', toughness: '1级' },
	{ talentType: '非人才', initiative: '五项素质出现0级者' }
];

export { fourManCols, fourData, wdCols, wdData, pjData, pjCols, srCols, srData, srdCols, quaData, qlCols, qlData, rcCols, rcData };
